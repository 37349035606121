import React from "react";

const Lantern = (props) => {
  const { className, contentClassName } = props;
  return (
    <svg
      viewBox="0 0 16 25"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Kingdom Death - Lantern Logo</title>
      <path
        className={contentClassName}
        d="m12.8,12.85l0.6,-0.9l-0.3,-0.6l0.6,-0.8l-0.6,-0.5l-0.5,-0.4l-3.1,-3.9c0.1,-0.1 0.2,-0.2 0.3,-0.3c0.1,-0.1 0.2,-0.3 0.3,-0.5c0.1,-0.2 0.2,-0.4 0.2,-0.5c0.1,-0.4 0,-0.8 -0.1,-1.2c-0.2,-0.4 -0.5,-0.8 -0.8,-1.1l-0.3,-0.2c-0.1,-0.1 -0.2,-0.1 -0.4,-0.1l-0.2,-0.1c-0.1,0 -0.1,0 -0.2,0l-0.1,0l-0.1,0l0,0l0,0l0,0l0,0c0,0 0.1,0 -0.1,0c-0.5,0 -1,0.2 -1.3,0.5c-0.4,0.3 -0.7,0.6 -0.8,1.1c0,0.1 -0.1,0.2 -0.1,0.3l0,0.2c0,0.1 0,0.1 0,0.1c0,0.1 0,0.2 0,0.3l0,0.1l0,0l0,0.1l0,0.1c0.1,0.4 0.2,0.7 0.4,1c0.1,0.1 0.2,0.2 0.3,0.3l-3.1,3.9l-0.5,0.4l-0.6,0.5l0.6,0.8l-0.3,0.6l0.6,0.9l0.3,5.9l0.6,1.2l-0.8,1.1l0.8,0.9c0,0 -0.3,1.1 0.8,1.7c0.5,0.3 1.1,0.5 1.1,0.5l-0.4,-0.8c0,0 0.5,-0.1 0.7,-0.3c0.2,-0.2 0.2,-0.3 0.2,-0.3l0.8,0.2l0.8,0l0,0l0.8,0l0.8,-0.2c0,0 0,0.1 0.2,0.3c0.2,0.2 0.7,0.3 0.7,0.3l-0.4,0.8c0,0 0.6,-0.2 1.1,-0.5c1.1,-0.7 0.8,-1.7 0.8,-1.7l0.8,-0.9l-0.8,-1.1l0.6,-1.2l0.1,-6zm-3.1,0.9c0,0.3 -0.1,0.5 -0.4,0.6c-0.3,0 -0.5,-0.1 -0.6,-0.4c0,-0.3 0.1,-0.5 0.4,-0.6c0.3,0 0.6,0.1 0.6,0.4zm-1.2,-3.6c0,-0.1 0.1,-0.3 0.2,-0.3c0.1,0 0.3,0.1 0.3,0.2c0,0.1 -0.1,0.3 -0.2,0.3c-0.2,0 -0.3,-0.1 -0.3,-0.2zm-2.1,-5.8c0,-0.1 0,-0.2 0,-0.3c0,0 0,-0.1 0,-0.1l0,-0.1c0,-0.1 0,-0.1 0.1,-0.2c0.1,-0.2 0.4,-0.4 0.6,-0.6c0.3,-0.1 0.5,-0.2 0.8,-0.2c-0.1,0 0,0 -0.1,0l0,0l0,0l0,0l0,0l0.1,0l0.1,0c0,0 0.1,0 0.1,0l0.1,0c0.1,0 0.1,0 0.2,0l0.2,0.1c0.2,0.1 0.5,0.3 0.6,0.6c0.1,0.2 0.2,0.5 0.2,0.8c0,0.2 0,0.3 -0.1,0.4c0,0.1 -0.1,0.2 -0.2,0.3c-0.1,0.1 -0.1,0.1 -0.2,0.2l-1.3,-0.1l0,0l-1.1,0.1c-0.1,-0.1 -0.1,-0.1 -0.2,-0.2c0,-0.1 -0.1,-0.2 -0.2,-0.3c0,-0.1 -0.1,-0.2 -0.1,-0.4l0.4,0l0,0l0,0l0,0zm2,7.4c0,0.3 -0.1,0.5 -0.4,0.6c-0.3,0 -0.5,-0.1 -0.6,-0.4c0,-0.3 0.1,-0.5 0.4,-0.6c0.3,-0.1 0.6,0.1 0.6,0.4zm-2.9,2.4c0,-0.6 0.5,-1.1 1.1,-1.1c0.6,0 1.1,0.5 1.1,1.1c0,0.6 -0.5,1.1 -1.1,1.1c-0.6,0 -1.1,-0.5 -1.1,-1.1zm3.1,5.3c-1,0.2 -1.9,-0.5 -2.1,-1.5c-0.2,-1 0.5,-1.9 1.5,-2.1c1,-0.2 1.9,0.5 2.1,1.5c0.1,1 -0.5,1.9 -1.5,2.1z"
      />
    </svg>
  );
};

export default Lantern;
