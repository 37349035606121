import React from "react";
import Lantern from "../Lantern";
import styles from "./GlobalNav.module.scss";

/* Imports must  be relative for this file! */

/* Inline Utility Functions */

const isString = (value) => {
  return Object.prototype.toString.call(value) === "[object String]";
};

const css = (...args) => {
  let stylesList = [];

  args
    .filter((style) => !!style)
    .forEach((style) => {
      if (Array.isArray(style)) {
        stylesList = stylesList.concat(css(...style));
      } else if (isString(style)) {
        stylesList.push(style);
      }
    });

  return stylesList.join(" ");
};

const SWITCH_POINT = 65;
const MINIMUM_OPACITY = 0.9;
const MOBILE_BREAKPOINT = 1024;

const GlobalNav = (props) => {
  const { navItems, specialLink, LanternWrapperDesktop, LanternWrapperMobile } =
    props;
  const [backgroundOpacity, setBackgroundOpacity] = React.useState(1);
  const [fixed, setFixed] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dirty, setDirty] = React.useState(false); // Has the nav been interacted with?

  const onAdjust = (e) => {
    if (window.innerWidth >= MOBILE_BREAKPOINT && open) {
      setOpen(false);
    }

    // Change the opacity of the nav background based on scroll position

    const completionPoint = window.innerHeight * 0.5;


    setFixed(window.location.href.indexOf("support.kingdomdeath.com") < 0);

    if (
      window.pageYOffset - SWITCH_POINT >= completionPoint &&
      backgroundOpacity !== MINIMUM_OPACITY
    ) {
      // Past the point for minimum opacity
      setBackgroundOpacity(MINIMUM_OPACITY);
    } else if (
      window.pageYOffset - SWITCH_POINT < completionPoint &&
      window.pageYOffset >= SWITCH_POINT
    ) {
      // Variable opacity
      const opacity =
        (1 - MINIMUM_OPACITY) *
          ((completionPoint - (window.pageYOffset - SWITCH_POINT)) /
            completionPoint) +
        MINIMUM_OPACITY;
      setBackgroundOpacity(opacity);
    } else if (window.pageYOffset < SWITCH_POINT && backgroundOpacity !== 1) {
      // Fully opaque
      setBackgroundOpacity(1);
    }
  };

  React.useEffect(() => {
    onAdjust();
    window.addEventListener("resize", onAdjust);
    window.addEventListener("scroll", onAdjust);
    return () => {
      window.removeEventListener("resize", onAdjust);
      window.removeEventListener("scroll", onAdjust);
    };
  });

  React.useEffect(() => {
    const AppWrapper = document.getElementById("__next");
    const AppWrapperAlt = document.getElementById("kd-wrapper");
    if (AppWrapper) {
      if (open) {
        AppWrapper.classList.add(styles.appLocked);
      } else {
        AppWrapper.classList.remove(styles.appLocked);
      }
    } else if (AppWrapperAlt) {
      if (open) {
        AppWrapperAlt.classList.add(styles.appLocked);
      } else {
        AppWrapperAlt.classList.remove(styles.appLocked);
      }
    }
  }, [open]);

 

  return (
    <div
      className={css(
        styles.wrapper,
        open && styles.open,
        dirty && styles.dirty
      )}
    >
      <div
        className={styles.navBackground}
        style={{
          opacity: backgroundOpacity,
          position: !!fixed ? "fixed" : undefined,
          top: !!fixed? '0px' : undefined
        }}
      />
      <div className={styles.nav}>
        <div className={styles.content}>
          <div className={styles.navItems}>
            <div className={css(styles.navItem, styles.desktopLogo)}>
              <LanternWrapperDesktop>
                <Lantern
                  className={styles.lantern}
                  contentClassName={styles.lanternInner}
                />
              </LanternWrapperDesktop>
            </div>
            {navItems}
          </div>
        </div>
        {specialLink}
      </div>
      <div className={styles.mobileBar}>
        <LanternWrapperMobile>
          <Lantern
            className={styles.lantern}
            contentClassName={styles.lanternInner}
          />
        </LanternWrapperMobile>
        <button
          className={styles.mobileMenuButton}
          onClick={() => {
            if (!dirty) {
              setDirty(true);
            }
            setOpen(!open);
          }}
        >
          <span className={styles.buttonLine} />
          <span className={styles.buttonLine} />
        </button>
      </div>
    </div>
  );
};

export default GlobalNav;
