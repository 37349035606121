import styles from "./SubNav.module.scss";
import React from "react";

const isString = (value) => {
  return Object.prototype.toString.call(value) === "[object String]";
};

const css = (...args) => {
  let stylesList = [];

  args
    .filter((style) => !!style)
    .forEach((style) => {
      if (Array.isArray(style)) {
        stylesList = stylesList.concat(css(...style));
      } else if (isString(style)) {
        stylesList.push(style);
      }
    });

  return stylesList.join(" ");
};

const ContentBlock = (props) => (
  <div {...props} className={css(props.className, styles.contentBlock)} />
);

const SubNav = (props) => {
  const { data } = props;
  const isInternalLink = (link) => {
    return /^\/.+/g.test(link);
  };

  const navItems = data.items.map((item, index) => {
    const { dataExtra, isLoginDependant, visibleLogged } = item;

    const attributes = {};

    if (!!dataExtra) {
      attributes["data-extra"] = dataExtra;
    }

    if (isLoginDependant) {
      attributes["data-login-visible"] = visibleLogged;
      attributes["data-key"] = `dep_${index}`;
    }
    const isFunction = function(obj) {
      return !!(obj && obj.constructor && obj.call && obj.apply);
    };
    // If the link starts with
    return (
      <div key={item.id} className={styles.navItem}>
        <div style={{display:"none"}} {...attributes}>
          <a
            className={styles.navLink}
            href={
              isInternalLink(item.link)
                ? `https://kingdomdeath.com${item.link}`
                : item.link
            }
            referrerPolicy="unsafe-url"
          >
            {item.label}
          </a>
        </div>
        {!!isLoginDependant && (
          <img
            src="index"
            onError={(e) => {
              if (isFunction(window.isLoginDependantLoad)) window.isLoginDependantLoad(e);
            }}
            style={{ display: "none" }}
          />
        )}
      </div>
    );
  });
  return (
    <div className={styles.wrapper}>
      <ContentBlock className={styles.content}>{navItems}</ContentBlock>
    </div>
  );
};

export default SubNav;
