import styles from './DropdownMenuButton.module.scss';

const DropdownMenuButton = (props) => {
  const css = (...args) => {
    let stylesList = [];
  
    args
      .filter((style) => !!style)
      .forEach((style) => {
        if (Array.isArray(style)) {
          stylesList = stylesList.concat(css(...style));
        } else if (isString(style)) {
          stylesList.push(style);
        }
      });
  
    return stylesList.join(' ');
  };
  const isString = (value) => {
    return Object.prototype.toString.call(value) === "[object String]";
  };
  const { label, link, elements, isFirst, isLast } = props;
  const items = elements.map((element) => {
    const { id, link, label, dataExtra, isLoginDependant, visibleLogged } =
      element;
    if (!isLoginDependant)
      return (
        <a key={id} href={link} data-extra={dataExtra}>
          {label}
        </a>
      );
    else
      return (
        <a key={id} href={link}  data-extra={dataExtra} data-login-visible={visibleLogged}>
          {label}
        </a>
      );
  });

  return (
    <div className={styles.dropdown}>
      <a className={styles.dropBtn} href={link}>
        {label}
      </a>
      <div
        className={css(
          styles.dropdownContent,
          isLast && styles.dropdownLast,
          isFirst && styles.dropdownFirst
        )}
      >
        {items}
      </div>
    </div>
  );
};

export default DropdownMenuButton;
