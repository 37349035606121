import React from 'react';
import ReactDOM from 'react-dom';
import * as focusVisible from 'focus-visible/dist/focus-visible.min.js';
import GlobalNav from '../components/GlobalNav/GlobalNav';
import SubNavGlobal from '../components/SubNavGlobal';
import * as menuData from './data.json';
import * as simulatorNavData from './simulatorData.json';
import normalize from '../../node_modules/normalize.css/normalize.css';
import styles from '../components/GlobalNav/GlobalNav.module.scss';
import DropdownMenuButton from '../components/DropdownMenuButton/index.js';
const { menu } = menuData;
const subNavMenu = simulatorNavData.allMenus[0];

const isInternalLink = (link) => {
  return /^\/.+/g.test(link);
};

const navItems = menu.items.map((item, index) => {
  switch (item._modelApiKey) {
    case 'dropdown_link':
      return (
        <DropdownMenuButton
          isFirst={index == 0}
          isLast={index === menu.items.length - 1}
          key={item.id}
          {...item}
        />
      );
    default:
      // If the link starts with
      return (
        <div key={item.id} className={styles.navItem}>
          <a
            className={styles.navLink}
            href={
              isInternalLink(item.link)
                ? `https://kingdomdeath.com${item.link}`
                : item.link
            }
          >
            {item.label}
          </a>
        </div>
      );
  }
});

const lanternWrapperDesktop = (props) => (
  <a href='https://kingdomdeath.com' className={styles.navLink}>
    {props.children}
  </a>
);

const lanternWrapperMobile = (props) => (
  <a href='https://kingdomdeath.com' className={styles.mobileLogo}>
    {props.children}
  </a>
);

const specialLink = (
  <div className={styles.specialLink}>
    <a className={styles.navLink} href={menu.specialLink.link}>
      {menu.specialLink.label}
    </a>
  </div>
);

window.addEventListener('load', function () {
  ReactDOM.render(
    <>
      <GlobalNav
        navItems={navItems}
        LanternWrapperDesktop={lanternWrapperDesktop}
        LanternWrapperMobile={lanternWrapperMobile}
        specialLink={specialLink}
      />
      <SubNavGlobal data={subNavMenu}/>
    </>,
    document.getElementById('kd-wrapper')
  );
});
